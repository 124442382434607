import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@services/modal/modal.service';
import { Profiles } from 'src/app/core/mocks/profiles.enum';
import { PaymentsFacadeService } from '../../payments-store/payments.facade.service';
import { ObservationDtoList } from '../../../interfaces/ICreditLetterDtoList.interface';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'bsc-observation-viewer',
  templateUrl: './observation-viewer.component.html',
  styleUrls: ['./observation-viewer.component.scss'],
})
export class ObservationViewerComponent implements OnInit {
  @Input() assetsList: any[] = [];
  @Input() size: 'xs' | 'sm' | 'st' | 'md' | 'lg' | 'lx' = 'sm';
  @Input() params!: any;
  @Input() payType: any;
  @Input() profile: any;
  @Input() userName: any;
  @Input() step7: any;

  paginador: any;
  tittleObservations: string = '';
  observations: any = [];

  showAlertError: boolean = false;
  showAlertObservation: boolean = false;
  showAlertObservationEdit: boolean = false;
  showAlertDeleteObservation: boolean = false;
  showAlertErrorEmty: boolean = false;
  selectedObservationIndex: number | null = null;
  originalObservation: any = null;
  dateNow = new Date();
  listDataTramites: any = [];
  pages: Array<any> = [];
  currentPage: any[] = [];
  profileType = Profiles;

  formObservation!: UntypedFormGroup;
  dateObservation!: string;
  updateObservation!: string;
  descriptionObservation!: string;
  rolObservation!: string;
  userObservation!: string;
  isEdit: boolean = true;
  observationTest!: any;
  idObservation: number = 0;
  decodedToken!: any;
  helper: any;
  constructor(
    private paymentsFacadeService: PaymentsFacadeService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.loadList();
    this.dateObservation = Date.now().toString();
    this.updateObservation = Date.now().toString();
    this.rolObservation = this.profile;
    this.userObservation = this.userName;
    this.listObservations();
  }

  listObservations() {
    this.observations = this.params.observations || [];
  }

  loadList() {
    this.tittleObservations = `Observaciones`;
  }

  editObservation(row: any, i: number) {
    this.isEdit = false;
    this.selectedObservationIndex = i;
    this.idObservation = i;
    this.originalObservation = { ...row };
    this.descriptionObservation = row.descriptionObservation;
    const updatedRow = { ...this.originalObservation, updateObservation: Date.now().toString() };
    this.observations = this.observations.map((observation: any) =>
      observation.idObservation === updatedRow.idObservation ? updatedRow : observation
    );
    this.params = {
      ...this.params,
      observations: [...this.observations],
    };

    this.selectpaymentType(this.params);
  }

  addObservation() {
    if (!Array.isArray(this.params.observations)) {
      this.params.observations = [];
    }

    this.observations = this.params.observations ? [...this.params.observations] : [];

    const observationNew: ObservationDtoList = {
      idObservation: (this.observations.length + 1).toString(),
      dateObservation: this.dateObservation,
      updateObservation: this.updateObservation,
      descriptionObservation: this.descriptionObservation,
      rolObservation: this.profile,
      userObservation: this.userName,
    };
    if (this.descriptionObservation == '') {
      this.showAlertErrorEmty = true;
      return;
    }
    this.observations.push(observationNew);

    this.params.observations = [...this.observations];
    this.selectpaymentType(this.params);
    this.resetData();
    this.showAlertObservation = true;
  }

  saveEditedObservation(row: any, i: number) {
    this.observations = this.observations.map((observation: any) => {
      if (observation.idObservation === row.idObservation) {
        return {
          ...observation,
          descriptionObservation: this.descriptionObservation,
          updateObservation: Date.now().toString(),
        };
      }
      return observation;
    });
    this.params = {
      ...this.params,
      observations: [...this.observations],
    };
    this.selectpaymentType(this.params);
    this.showAlertObservationEdit = true;
    this.resetData();
    this.isEdit = true;
  }

  cancelEdit() {
    if (this.originalObservation) {
      const index = this.observations.findIndex(
        (observation: any) =>
          observation.idObservation === this.originalObservation.idObservation
      );
      if (index !== -1) {
        this.observations = [
          ...this.observations.slice(0, index),
          { ...this.originalObservation },
          ...this.observations.slice(index + 1),
        ];
      }
    }

    this.resetData();
  }

  deleteObservation(row: any, i: number) {
    this.observations = this.observations.filter((_: any, index: number) => index !== i);
    if (this.observations.length === 0) {
      this.observations = [];
    }
    this.params.observations = [...this.observations];
    this.selectpaymentType(this.params);
    this.showAlertDeleteObservation = true;
  }

  close() {
    this.modalService.close();
  }

  selectpaymentType(data: any) {
    switch (this.payType) {
      case 'Transfer':
        this.paymentsFacadeService.updatedPaymentTransfer(data);
        break;
      case 'Service':
        this.paymentsFacadeService.updatedPaymentServices(data);
        break;
      case 'CreditLetter':
        this.paymentsFacadeService.updatedPaymentLetter(data);
        break;
      default:
        break;
    }
  }

  resetData() {
    this.descriptionObservation = '';
    this.dateObservation = Date.now().toString();
    this.updateObservation = Date.now().toString();
    this.showAlertError = false;
    this.showAlertObservation = false;
    this.showAlertDeleteObservation = false;
    this.showAlertObservationEdit = false;
    this.isEdit = true;
  }
}
