import { Injectable } from '@angular/core';
import { Numbers } from 'src/app/core/constants/numbers';
import { ApiHelperService } from 'src/app/data/services/api-helper/api-helper.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIS_URL } from 'src/app/core/constants/url';
import { Profiles } from 'src/app/core/mocks/profiles.enum';
import { CommissionCredit, IHistoricCommission } from 'src/app/modules/master/logistics-coordination/components/payments/interfaces/ICommissionCredit';
import { CreditLetterDtoList, ICreditLetterDtoList, GirosDtoList } from 'src/app/modules/master/logistics-coordination/components/payments/interfaces/ICreditLetterDtoList.interface';
import { IPayment } from 'src/app/modules/master/logistics-coordination/components/payments/interfaces/payment.interface';
import { ParameterTypeList } from 'src/app/modules/master/logistics-coordination/components/payments/interfaces/paymentMethod.interface';
import { ICardValues } from 'src/app/modules/master/logistics-coordination/components/payments/interfaces/paymentsValuesCard.interface';
import { ProfileFacadeService } from 'src/app/modules/master/profile-store/profile.facade.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  private endPointPagos = APIS_URL.payments;
  private endPointMastersTypesPayments = APIS_URL.mastersTypesPayments;
  private endPointPagosHijos = APIS_URL.paymentsChild;
  private endPointFiles = APIS_URL.files;
  private profile!: Profiles;

  constructor(
    private apiHelperService: ApiHelperService,
    private profileFacadeService: ProfileFacadeService,
  ) {
    // This is intentional
    this.profileFacadeService.getCurretProfile().subscribe((profile) => {
      if (profile) {
        this.profile = profile?.authorities[0];
      }
    });
  }

  getMastersTypes(id: number): Observable<ParameterTypeList[]> {
    return this.apiHelperService
      .get(`${this.endPointMastersTypesPayments}${id}`)
      .pipe(map((data: any) => data.parameterTypeList));
  }

  getTypeMethod(): Observable<ParameterTypeList[]> {
    return this.getMastersTypes(Numbers._23);
  }

  getAgreedDocument(): Observable<ParameterTypeList[]> {
    return this.getMastersTypes(Numbers._26);
  }

  getPaymentsValues(ImportId: number): Observable<ICardValues> {
    const params = `?ImportId=${ImportId}`;
    return this.apiHelperService
      .post(`${this.endPointPagos}${params}`);
  }

  updatePaymentStatus(payments: number[], typeUpdate: string, customs: string): Observable<any> {
    const params = `/updateRequestStatus?typeUpdate=${typeUpdate}&customs=${customs}`;
    return this.apiHelperService
      .put(`${this.endPointPagosHijos}${params}`, payments);
  }

  createPayments(payments: IPayment[], importNumber: string): Observable<any> {
    const obj = {
      "paymentChild": payments
    }
    const params = `?importNumber=${importNumber}`;
    return this.apiHelperService
      .post(`${this.endPointPagosHijos}${params}`, obj);
  }

  sendEmailCustom(importNumber: string, billNumber: number, DatePayment: string, customs: string): Observable<any> {
    const params = `/send-email-custom?importNumber=${importNumber}&billNumber=${billNumber}&datePayment=${DatePayment}&customs=${customs}`;
    return this.apiHelperService
      .post(`${this.endPointPagos}${params}`, {});
  }

  getFilesPayment(importNumber: string): Observable<any> {
    const params = `/payments?importNumber=${importNumber}`;
    return this.apiHelperService
      .get(`${this.endPointFiles}${params}`);
  }

  updatedPaymentLetter(payment: CreditLetterDtoList): Observable<any> {
    return this.updatedPayment("update-credit-letter", payment);
  }

  updatedPaymentTransfer(payment: GirosDtoList): Observable<any> {
    return this.updatedPayment("update-transfer", payment as any);
  }

  updatedPaymentServices(payment: CreditLetterDtoList): Observable<any> {
    return this.updatedPayment("update-services", payment);
  }
  private updatedPayment(path: string, payment: CreditLetterDtoList): Observable<any> {
    const params = `/${path}?id=${payment?.paymentChildId}`;
    return this.apiHelperService
      .put(`${this.endPointPagosHijos}${params}`, payment);
  }

  deletePayment(payment: CreditLetterDtoList): Observable<any> {
    const params = `/by-id/${payment?.paymentChildId}`;
    return this.apiHelperService
      .delete(`${this.endPointPagosHijos}${params}`);
  }

  getCreditLetter(importNumber: string, page: number, size: number): Observable<ICreditLetterDtoList> {
    let path = "credit-letter";
    if (this.profile === Profiles.COMMERCIAL || this.profile === Profiles.CLIENT) {
      path = "credit-letter-clients";
    }
    return this.getPayments(path, importNumber, page, size);
  }

  getTransfer(importNumber: string, page: number, size: number): Observable<ICreditLetterDtoList> {
    let path = "bank-transfer";
    if (this.profile === Profiles.COMMERCIAL || this.profile === Profiles.CLIENT) {
      path = "bank-transfer-clients";
    }
    return this.getPayments(path, importNumber, page, size);
  }

  getServices(importNumber: string, page: number, size: number): Observable<ICreditLetterDtoList> {
    let path = "services";
    if (this.profile === Profiles.COMMERCIAL || this.profile === Profiles.CLIENT) {
      path = "services-clients";
    }
    return this.getPayments(path, importNumber, page, size);
  }

  private getPayments(path: string, importNumber: string, page: number, size: number): Observable<ICreditLetterDtoList> {
    const params = `/${path}?importNumber=${importNumber}&page=${page}&size=${size}`;
    return this.apiHelperService
      .get(`${this.endPointPagosHijos}${params}`)
      .pipe(map((data: ICreditLetterDtoList) => data));
  }

  addCommission(commissionCredit: CommissionCredit, importNumber: string, lcNumber: string): Observable<any> {
    const params = `/credit-letter/add-commissions?importNumber=${importNumber}&lcNumber=${lcNumber}`;
    return this.apiHelperService
      .post(`${this.endPointPagosHijos}${params}`, commissionCredit)
      .pipe(map((data: any) => data.Message));
  }

  updateCommission(commissionCredit: CommissionCredit[], importNumber: string, lcNumber: string): Observable<any> {
    const params = `/credit-letter/update-commissions?importNumber=${importNumber}&lcNumber=${lcNumber}`;
    return this.apiHelperService
      .post(`${this.endPointPagosHijos}${params}`, commissionCredit)
      .pipe(map((data: any) => data));
  }

  deleteCommission(importNumber: string, lcNumber: string): Observable<any> {
    const params = `/commissions?importNumber=${importNumber}&lcNumber=${lcNumber}`;
    return this.apiHelperService
      .delete(`${this.endPointPagosHijos}${params}`);
  }

  getEveryCommision(importNumber: string, lcNumber: string): Observable<CommissionCredit[]> {
    const params = `/credit-letter/every-commission-calc?importNumber=${importNumber}&lcNumber=${lcNumber}`;
    return this.apiHelperService
      .get(`${this.endPointPagosHijos}${params}`)
      .pipe(map((data: CommissionCredit[]) => data));
  }

  getHistoricCommission(importNumber: string, lcNumber: string, event: string, page: number, size: number): Observable<IHistoricCommission> {
    const params = `/credit-letter/historic?importNumber=${importNumber}&lcNumber=${lcNumber}&event=${event}&page=${page}&size=${size}`;
    return this.apiHelperService
      .get(`${this.endPointPagosHijos}${params}`)
      .pipe(map((data: IHistoricCommission) => data));
  }

  deletePaymentTwo(payment: CreditLetterDtoList, reasonForDeletePayment: string): Observable<any> {
    const params = `/by-id?id=${payment?.paymentChildId}&reasonForDeletePayment=${reasonForDeletePayment}`;
    return this.apiHelperService
      .delete(`${this.endPointPagosHijos}${params}`);
  }
}
