import { Injectable } from '@angular/core';
import { BcsModalComponent } from 'src/app/shared/components/bcs-modal/bcs-modal.component';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { DocumentViewerComponent } from 'src/app/modules/master/logistics-coordination/components/procedures/modal/document-viewer/document-viewer.component';
import { StatementDocumentationModalComponent } from 'src/app/modules/master/temporary/components/statement-documentation-modal/statement-documentation-modal.component';
import { CustomTempDocumentCreate } from 'src/app/modules/master/temporary/data/models/custom-temp-document-create';
import { TableTransportViewModelComponent } from 'src/app/modules/master/quotation/admin/components/table-container-view-modal/table-transport-view-model.component';
import { ObservationViewerComponent } from 'src/app/modules/master/logistics-coordination/components/payments/componentes/modal/observation-viewer/observation-viewer.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private ngbModal: NgbModal) {
    // This is intentional
  }

  modalOptions: NgbModalOptions = {
    backdrop: 'static',
    backdropClass: 'customBackdrop',
    centered: true,
  };

  public modalRef!: NgbModalRef;

  public showSiNo(
    titulo: string,
    mensaje: string,
    Icon: 'check' | 'error' | 'stop' | 'lightbulb',
    textButton: string = 'Crear'
  ) {
    this.modalRef = this.ngbModal.open(BcsModalComponent, this.modalOptions);
    this.modalRef.componentInstance.typeModal = 'si';
    this.modalRef.componentInstance.titulo = titulo;
    this.modalRef.componentInstance.mensaje = mensaje;
    this.modalRef.componentInstance.Icon = Icon;
    this.modalRef.componentInstance.textButton = textButton;
    return this.modalRef.result;
  }

  public showOk(
    titulo: string,
    mensaje: string,
    LabelButton: string,
    Icon: string
  ) {
    this.modalRef = this.ngbModal.open(BcsModalComponent, this.modalOptions);
    this.modalRef.componentInstance.typeModal = 'ok';
    this.modalRef.componentInstance.titulo = titulo;
    this.modalRef.componentInstance.LabelButton = LabelButton;
    this.modalRef.componentInstance.mensaje = mensaje;
    this.modalRef.componentInstance.Icon = Icon;
    return this.modalRef.result;
  }

  public sessionTime() {
    this.modalRef = this.ngbModal.open(BcsModalComponent, this.modalOptions);
    this.modalRef.componentInstance.typeModal = 'si';
    this.modalRef.componentInstance.modalType = 'time';
    this.modalRef.componentInstance.titulo = '¿Sigues con nosotros?';
    this.modalRef.componentInstance.mensaje = 'Tu sesión se cerrará en:';
    this.modalRef.componentInstance.textButton = 'Continuar';
     return this.modalRef.closed;
  }

  public files(paymentChildId: number, importNumber: string) {
    this.modalRef = this.ngbModal.open(BcsModalComponent, this.modalOptions);
    this.modalRef.componentInstance.typeModal = 'files';
    this.modalRef.componentInstance.modalType = 'files';
    this.modalRef.componentInstance.paymentChildId = paymentChildId;
    this.modalRef.componentInstance.importNumber = importNumber;
  }

  public documentViewer(params: any) {
    const option = {
      ...this.modalOptions,
      modalDialogClass: 'fullScreen'
    }
    this.modalRef = this.ngbModal.open(DocumentViewerComponent, option);
    this.modalRef.componentInstance.params = params;
  }

  public transportDataViewer(transportContainer: any, transportLooseLoad: any, isLocal: boolean) {
    const option = {
      ...this.modalOptions,
      modalDialogClass: 'fullScreen'
    }
    this.modalRef = this.ngbModal.open(TableTransportViewModelComponent, option);
    this.modalRef.componentInstance.transportContainer = transportContainer;
    this.modalRef.componentInstance.transportLooseLoad = transportLooseLoad;
    this.modalRef.componentInstance.isLocal = isLocal;
  }

  public documentTemporary(importNumber: string, username: string, CustomTempDocument: CustomTempDocumentCreate) {
    const option = {
      ...this.modalOptions,
      modalDialogClass: 'fullScreen'
    }
    this.modalRef = this.ngbModal.open(StatementDocumentationModalComponent, option);
    this.modalRef.componentInstance.CustomTempDocument = CustomTempDocument;
    this.modalRef.componentInstance.importNumber = importNumber;
    this.modalRef.componentInstance.username = username;
  }

  public close() {
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }

  public observationsPayment(params: any, profile: any, username: any, methodpay: any, step7: any){
    const option = {
      ...this.modalOptions,
      modalDialogClass: 'fullScreen'
    }
    this.modalRef = this.ngbModal.open(ObservationViewerComponent, option);
    this.modalRef.componentInstance.params = params;
    this.modalRef.componentInstance.profile = profile;
    this.modalRef.componentInstance.userName = username;
    this.modalRef.componentInstance.payType = methodpay;
    this.modalRef.componentInstance.step7 = step7;
  }
}
