<div class="observation-viewer">
  <bc-table-container class="bc-col-12 bc-w-100" [dataTable]="currentPage">
    <bc-table-header [title]="tittleObservations"></bc-table-header>
    <bc-table-content>
      <table caption="tabla" bc-table [selection]="false" [sort]="false">
        <caption></caption>
        <thead>
          <tr>
            <th scope="row" bc-cell type="text">Fecha creación</th>
            <th scope="row" bc-cell type="text">Fecha actualización</th>
            <th scope="row" bc-cell type="text">Observación</th>
            <th scope="row" bc-cell type="text">Usuario</th>
            <th scope="row" bc-cell type="text"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let observation of observations; let i = index">
            <td bc-cell> {{ observation.dateObservation | date : 'dd/MM/yyyy' }} </td>
            <td bc-cell> {{ observation.updateObservation | date : 'dd/MM/yyyy' }} </td>
            <td bc-cell>{{ observation.descriptionObservation }}</td>
            <td bc-cell>{{ observation.userObservation }}</td>
            <td bc-cell>
              <button *ngIf="isEdit && observation.userObservation == userName && !step7"
              mat-icon-button (click)="editObservation(observation, i)">
                <mat-icon id="editItemFromPayments">edit</mat-icon>
              </button>
              <button *ngIf="!isEdit && selectedObservationIndex === i && !step7"
              mat-icon-button (click)="saveEditedObservation(observation, i)">
                <mat-icon id="editItemFromPaymentsSaved">save</mat-icon>
              </button>
              <button *ngIf="!isEdit && selectedObservationIndex === i && !step7"
               mat-icon-button (click)="cancelEdit()">
                <mat-icon id="cancelItemFromPayments">cancel</mat-icon>
              </button>
              <button *ngIf="isEdit && observation.userObservation == userName && !step7 "
              mat-icon-button (click)="deleteObservation(observation, i)">
                <mat-icon id="deleteItemFromPayments">delete_outline</mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </bc-table-content>
    <br /><br />
  </bc-table-container>
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <div class="regresar-button">
        <h3>Descripción de la observación: </h3>
        <textarea
          matInput
          #description
          [(ngModel)]="descriptionObservation"
          maxlength="600"
          class="textarea"
          rows="2"
          placeholder="Ingrese aquí la observación para el pago..."
        ></textarea>
      </div>
    </div>
  </div>
  <div class="bc-row">
    <div class="bc-col-12 bc-p-5 centering">
      <bcs-button *ngIf="isEdit && descriptionObservation != '' && !step7" Label="Guardar nueva observación" (click)="addObservation()"></bcs-button>
      <bcs-button Label="Regresar" (click)="close()" TypeButton="secondary"></bcs-button>
    </div>
  </div>  
</div>

<app-bcs-alert [Show]="showAlertObservation"
AlertTitle="Registro de observación"
AlertText="Se adicionó la observación."
AlertType="bc-alert-success" AlertIcon="ok"></app-bcs-alert>
<app-bcs-alert [Show]="showAlertObservationEdit"
AlertTitle="Edición de observación"
AlertText="Se editó la observación."
AlertType="bc-alert-success" AlertIcon="ok"></app-bcs-alert>
<app-bcs-alert [Show]="showAlertDeleteObservation"
AlertTitle="Eliminación observación"
AlertText="Se eliminó la observación."
AlertType="bc-alert-success"
AlertIcon="ok"></app-bcs-alert>
<app-bcs-alert [Show]="showAlertError"
AlertTitle="Error en el proceso"
AlertText="Error al realizar el proceso"
AlertType="bc-alert-error" AlertIcon="error"></app-bcs-alert>
<app-bcs-alert [Show]="showAlertErrorEmty"
AlertTitle="Error en el proceso"
AlertText="Observación vacia"
AlertType="bc-alert-error" AlertIcon="error"></app-bcs-alert>
