import { Injectable } from '@angular/core';
import { ICommissionValue } from '@interfaces/IComissionValue.interface';
import { IContractActivation } from '@interfaces/IContractActivation.interface';
import { IExchangeAudit } from '@interfaces/IExchangeAudit.interface';
import { IIntTransportContainerAll } from '@interfaces/IIntTransportContainerAll.interface';
import { BodyLegalProvPoliciese, ILegalProvPolicieseEmb } from '@interfaces/ILegalProvPoliciese.interface';
import { ISettlementImportSummary } from '@interfaces/ISettlementImportSummary.interface';
import { SettlementSteps } from '@interfaces/ISettlementsSteps.interface';
import { ApiHelperService } from '@services/api-helper/api-helper.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { APIS_URL } from 'src/app/core/constants/url';

@Injectable({
  providedIn: 'root',
})
export class LiquidationService {
  private endPointSettlements = APIS_URL.settlements;
  private endPointExchangeAudit = APIS_URL.exchangeAudit;
  private endPointIntTransportContainer = APIS_URL.internationalTransportContainer;
  private endPointFormalitiesDocuments = APIS_URL.formalitiesDocuments;
  private endPointCommissionValue = APIS_URL.commissionValue;
  private endPointLegalProvPolicies = APIS_URL.legalProvPolicies;
  private endPointContractActivation = APIS_URL.contractActivation;

  constructor(private apiHelperService: ApiHelperService) {
    // This is intentional
  }

  getSettlementImportSummary(
    importNumber: string,
    settlementUser: string
  ): Observable<ISettlementImportSummary> {
    const params = `/importSummary?importNumber=${importNumber}&settlementUser=${settlementUser}`;
    return this.apiHelperService
      .get(this.endPointSettlements + params)
      .pipe(map((data: any) => data.ImportSummary));
  }
  getDispatchs(importNumber: string): Observable<any[]> {
    const params = `/dispatchs?importNumber=${importNumber}`;
    return this.apiHelperService
      .get(this.endPointExchangeAudit + params)
      .pipe(map((data: any) => data.DISPATCH));
  }
  approveStep(
    settlementId: number,
    body: any
  ): Observable<any> {
    const params = `?settlementId=${settlementId}`;
    return this.apiHelperService.put(
      this.endPointSettlements + params,
      body
    );
  }

  getSettlementsSteps(importNumber: string): Observable<SettlementSteps> {
    const params = `?importNumber=${importNumber}`;
    return this.apiHelperService.get(this.endPointSettlements + params).pipe(
      map((data: any) => data.Settlement)
    );
  }

  getFormalitiesDocuments(importNumber: string): Observable<any[]> {
    const params = `/filter?importNumber=${importNumber}`;
    return this.apiHelperService
      .get(this.endPointFormalitiesDocuments + params)
      .pipe(map((data: any) => data.FormalitiesDocuments));
  }

  getExchangeAudit(importNumber: string): Observable<IExchangeAudit[]> {
    const params = `?importNumber=${importNumber}`;
    return this.apiHelperService
      .get(this.endPointExchangeAudit + params)
      .pipe(map((data: any) => data.Exchange_Audit));
  }

  postExchangeAudit(settlementId: number, exchangeAudit: any): Observable<IExchangeAudit[]> {
    const params = `?settlementsId=${settlementId}`;
    return this.apiHelperService
      .post(this.endPointExchangeAudit + params, exchangeAudit)
      .pipe(map((data: any) => data.Exchange_Audit));
  }

  downloadDescriptions(importNumber: string): Observable<any> {
    const params = `/descriptions?importNumber=${importNumber}`;
    return this.apiHelperService.get(this.endPointExchangeAudit + params, "", { responseType: 'blob' })
      .pipe(
        tap((blob: any) => {
          const enlace = document.createElement('a');
          enlace.download = `Descripcion_${importNumber}.xlsx`;
          enlace.href = URL.createObjectURL(blob);
          enlace.click();
        })
      );
  }
  getIntTransportContainerAll(
    importNumber: string,
    pageindex: number,
    pageSize: number
  ): Observable<IIntTransportContainerAll> {
    const params = `/all?importNumber=${importNumber}&page=${pageindex}&size=${pageSize}`;
    return this.apiHelperService
      .get(this.endPointIntTransportContainer + params)
      .pipe(map((data: any) => data));
  }
  changeExpensesCausedAndPaid(
    settlementId: number
  ): Observable<any> {
    const params = `/change-expenses-caused-and-paid?settlementId=${settlementId}`;
    return this.apiHelperService.put(
      this.endPointSettlements + params
    );
  }

  changeTransportPolicyCausesAndPaid(
    settlementId: number
  ): Observable<any> {
    const params = `/change-transport-policy-causes-and-paid?settlementId=${settlementId}`;
    return this.apiHelperService.put(
      this.endPointSettlements + params
    );
  }

  getCommissionValue(
    importNumber: string,
    settlementId: number,
  ): Observable<ICommissionValue> {
    const params = `/importNumber?settlementsId=${settlementId}&importNumber=${importNumber}`;
    return this.apiHelperService
      .get(this.endPointCommissionValue + params)
      .pipe(map((data: any) => data.Commission_Value));
  }

  putCommissionValue(
    commissionValueId: number,
    body: any,
  ): Observable<any> {
    const params = `?commissionValueId=${commissionValueId}`;
    return this.apiHelperService
      .put(this.endPointCommissionValue + params, body)
  }

  getLegalProvPolicies(
    settlementId: number,
    pageindex: number,
    pageSize: number
  ): Observable<ILegalProvPolicieseEmb> {
    const params = `/findAll?settlementId=${settlementId}&page=${pageindex}&size=${pageSize}`;
    return this.apiHelperService
      .get(this.endPointLegalProvPolicies + params)
      .pipe(map((data: any) => data));
  }

  postLegalProvPolicies(settlementId: number, body: BodyLegalProvPoliciese): Observable<any> {
    const params = `/save?settlementsId=${settlementId}`;
    return this.apiHelperService
      .post(this.endPointLegalProvPolicies + params, body)
      .pipe(map((data: any) => data));
  }

  putLegalProvPolicies(id: number, body: BodyLegalProvPoliciese): Observable<any> {
    const params = `?id=${id}`;
    return this.apiHelperService
      .put(this.endPointLegalProvPolicies + params, body)
      .pipe(map((data: any) => data));
  }

  deleteLegalProvPolicies(id: number): Observable<any> {
    const params = `?id=${id}`;
    return this.apiHelperService
      .delete(this.endPointLegalProvPolicies + params)
      .pipe(map((data: any) => data));
  }

  reportInconsistency(body: any): Observable<any> {
    const params = `/send-inconsistency`;
    return this.apiHelperService
      .post(this.endPointSettlements + params, body)
      .pipe(map((data: any) => data));
  }

  postClientNotification(importNumber: string, importValueCop: string, importCommission: string, files: any[]): Observable<any> {
    
    const formdata = new FormData();
    files?.forEach((file) => {
      formdata.append('files', file);
    });
    const params = `/send-settlement-client?importNumber=${importNumber}&importValueCop=${importValueCop}&importCommission=${importCommission}`;
    return this.apiHelperService
      .post(this.endPointSettlements + params, formdata)
      .pipe(map((data: any) => data));
  }

  getContractActivation(importNumber: string,): Observable<IContractActivation[]> {
    const params = `?importNumber=${importNumber}`;
    return this.apiHelperService
      .get(this.endPointContractActivation + params)
      .pipe(map((data: any) => data.Contract_Activation));
  }

  postContractActivation(settlementId: number, body: any): Observable<any> {
    const params = `?settlementsId=${settlementId}`;
    return this.apiHelperService
      .post(this.endPointContractActivation + params, body)
      .pipe(map((data: any) => data));
  }

  getSettlementsImport(importNumber: string): Observable<SettlementSteps> {
    const params = `/settlement-import?importNumber=${importNumber}`;
    return this.apiHelperService.get(this.endPointSettlements + params).pipe(
      map((data: any) => data.Settlement)
    );
  }

}
